import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Header, { HeaderLeft, HeaderCenter, HeaderRight } from '../../components/Dealer/Header';
import { TCPLogo } from '../../assets/images';
import Loader from 'shared/Loader';

import { updateCustomer, getCustomerApiInitiate } from '../../redux/actions/sales';

function AddDealer(props) {

    const {
        history,
        customer,
        getCustomerApiInitiate,
        actionLoading,
        updateCustomer
    } = props;

    useEffect(() => { 
        const customerId = localStorage.getItem('customerId'); 
        getCustomerApiInitiate(customerId) 
    }, [])

    const handleSubmit = evt => {

        evt.preventDefault();
        let temp_customer = {
            ...customer,
        }
        updateCustomer(history, '/basic', temp_customer)
    }

    return (
        <div className="dealer">

            { actionLoading && <Loader />}

            <Header>
                <HeaderLeft></HeaderLeft>
                <HeaderCenter>
                    <div className="header-main">
                        <img className="main-logo" src={TCPLogo} alt="" />
                    </div>
                </HeaderCenter>
                <HeaderRight></HeaderRight>
            </Header>

            <form action="javascript:void(0)" onSubmit={(e) => handleSubmit(e)} noValidate>
                <div className="container">
                    <div className="styled-form">
                        <div className="welcome-text">
                            <p><b>WELCOME!</b>
                            <br></br><br></br> Thank you for accessing Travis Capital Partners’ secure online credit application in connection with your purchase from 
                            <b> {localStorage.getItem('dealer_name')} </b>. 
                            After pressing “Begin” below, you will be asked to provide personal identifying and financial information. Please answer all of the questions accurately to the best of your ability. By completing this application, you will be authorizing [Name of Dealer] and Travis Capital Partners and their subsidiaries, affiliates and assigns to make whatever inquiries it deems necessary in connection with this credit application. You further certify that all information you provide is a true representation of the facts and is made for the purpose of obtaining the requested credit.
                            <br></br><br></br>Please contact Travis Capital Partners at (800) 832-2806 or 
                            <b> {localStorage.getItem('dealer_name')} </b> at <b> {localStorage.getItem('dealer_number')} </b> with any questions.</p>
                        </div>
                    </div>
                </div>
                <div className="footer-container">
                    <button className="secondary" type="submit">Begin</button>
                </div>
            </form>

        </div>
    )
}

const mapStateToProps = state => ({
    customer: state.sales.customer,
    actionLoading: state.sales.actionLoading
});

const mapDispatchToProps = dispatch => ({
    getCustomerApiInitiate: (data) => dispatch(getCustomerApiInitiate(data)),
    updateCustomer: (history, path, data) => dispatch(updateCustomer(history, path, data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddDealer);